import React from 'react'
import styled from 'styled-components'
import { ComposedChart, XAxis, YAxis, Tooltip, Legend, CartesianGrid, LabelList, Bar, Line, ResponsiveContainer } from 'recharts'
import { useDevices, useIsTV, useWindowSize } from '../../util/Util';

const renderLegend = (props) => {
    const { payload } = props;
  
    return (
        <div style={{
            display: 'flex', 
            justifyContent: 'space-evenly'
        }}>
            {payload.map(({ color, value }, index) => {
                return <div key={value} style={{display: 'flex', alignItems: 'center'}}>
                    <span style={{
                        width: '10px',
                        height: '10px',
                        background: color,
                        display: 'block',
                        marginRight: '5px'
                    }}/>
                    <span style={{ color: '#868686', fontSize: '0.8em' }}>{value}</span>
                </div>;
            })}
        </div>
    );
}


export default function SalesGraph({data}) {


    let barSize = 3;

    
	const [mobile, tablet, desktop] = useDevices();
	const [width, height] = useWindowSize()
    const isTV = useIsTV();

    if (isTV) barSize = 5;

    // TODO: Temporary fix for pallets.
    let isPallet = data && data.productInfo?.art_nr.includes('X');

    let graphData = data ? data.salesGraph : [];


	let special = data && data.productInfo?.sum_total == 'true';
	
	graphData = graphData.map(dp => {
		let percentage = special ? (isPallet ? dp.vismaPalletPercentage : dp.vismaBoxPercentage) : 1;

		let visma = dp.vismaPalletSales + dp.vismaBoxSales

		return {
			week: dp.week,
			forecast: dp.forecast,
			visma: visma * percentage,
			sb_standard: dp.amount_sold_standard * percentage,
			sb_premium: dp.amount_sold_premium * percentage,
            percentage: percentage
		}
	})

    if (mobile) {
		graphData = graphData?.filter((d,i) => i != 0 && i < 8).reverse();	
    } else if (tablet) {
        graphData = graphData?.filter((d,i) => i != 0 && i < 10).reverse();	
    } else {
		graphData = graphData?.filter((d,i) => i != 0 && i < 15).reverse();	
    }


    return (
        <Style>
            <ResponsiveContainer width="100%" height={isTV ? (300) : (!mobile ? 230 : 180)}>
                <ComposedChart
                    data={graphData}
                    width="100%"
                    margin={{ top: mobile ? 0 : 50, right: 0, bottom: 0, left: 0 }}
                >

                    <defs>
                        <linearGradient id="colorTest" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="0%" stopColor="#924b77" stopOpacity={1}/>
                            <stop offset="100%" stopColor="#52568b" stopOpacity={1}/>
                        </linearGradient>
                    </defs>

                    <XAxis dataKey='week' />
                    <Legend content={renderLegend} />

                    {true && <Tooltip
                        formatter={function(value, name) {
                            return `${Math.round(value)}`;
                        }}
                        labelFormatter={function(value) {
                            return `Vecka ${value}`;
                        }}
                        contentStyle={{
                            border: '1px solid rgba(96, 96, 96, 0.47)'
                        }}
                    />}


                    <Bar name="Forecast" dataKey='forecast' radius={[10, 10, 0, 0]} barSize={barSize} fill='#FF7750'></Bar>
                    <Bar name="Visma" dataKey='visma' radius={[10, 10, 0, 0]} barSize={barSize} fill='#5E6999'></Bar>
                    <Bar name="SB Standard" dataKey='sb_standard' radius={[0, 0, 0, 0]} barSize={barSize} stackId='a' fill='#B75D69'></Bar>
                    <Bar name="SB Premium" dataKey='sb_premium' radius={[10, 10, 0, 0]} barSize={barSize} stackId='a' fill='#C8AD55'></Bar>
                    

                </ComposedChart>
            </ResponsiveContainer>
        </Style>
    )
}

const Style = styled.div`


    .recharts-text.recharts-cartesian-axis-tick-value {
        // fill: white !important;
        font-size: 0.7em;
    }

`