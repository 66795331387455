import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import LoaderContext from '../context/LoaderContext';
import TuesdayWine from '../util/TuesdayWine';
import jwtDecode from 'jwt-decode';
import { epochToLocalDateTime } from '../util/Util';
import { useHistory } from 'react-router-dom';

export default function VinimarkOrder({match}) {
    let {order_id} = match.params;
    
    const history = useHistory();

    const {setLoader, loggedIn} = useContext(LoaderContext);
    const [createLoading, setCreateLoading] = useState(false)
    const [deleting, setDeleting] = useState(null)
    const [loadingStatus, setLoadingStatus] = useState(false)

    const [order, setOrder] = useState(null)

    function handleShow(analysis) {
        return () => {
            history.push(`/analyze/robertson/${order_id}/${analysis.id}`);
        }
    }
    
    function handleDelete(analysis) {
        return async () => {

            const confirm = window.confirm('Are you sure you want to delete this analysis?')
            if (!confirm) return;

            setDeleting(analysis.id)

            let res = await TuesdayWine.delete(`/analyze/${analysis.id}`)

            setDeleting(null)

            if (res === undefined || res.success !== true) {
                return alert('Failed to delete analysis');
            }

            load();
        }
    }

    async function handleCreate() {

        const lotNumber = prompt('Enter lot number');
        if (!lotNumber || lotNumber.trim() == '') return alert('Invalid lot number');

        const cases = prompt('Enter number of cases times 4, defaults to 0');

        setCreateLoading(true)

        let res = await TuesdayWine.post(`/analyze`, {
            order_id,
            producer: 'VM',
            lot: lotNumber,
            cases: Number(cases ?? 0)
        })


        if (res === undefined || res.analysis === undefined) {
            setCreateLoading(false)
            alert('Failed to create analysis');
            return;
        }

        setTimeout(() => {
            history.push(`/analyze/robertson/${order_id}/${res.analysis}`);
        }, 500)
    }

    function handleSetStatus(analysis, status) {
        return async () => {
            setLoadingStatus(analysis.id)
            let res = await TuesdayWine.put(`/analyze/${analysis.id}/${status}`)

            setLoadingStatus(false)

            if (res === undefined || res.success !== true) {
                return alert('Failed to set status');
            }

            load();
        }
    }


    async function load() {
        setLoader(true)
        setOrder((await TuesdayWine.get(`/analyze/VM?order_id=${order_id}`)).analysis?.[0])
        setLoader(false)
    }

    useEffect(() => { load() }, [])


    const user = jwtDecode(loggedIn);
    const role = user.role;
    if (!user || (role !== 1 && role !== 2 && role !== 3)) {
        return <><h2>Not allowed</h2></>
    }

    if (!order) return <></>

    const sumCases = order.analysis.reduce((acc, a) => {
        return acc + (a.cases ?? 0)
    }, 0);

    const missingCases = order.order.quantity - sumCases;

    return (
        <Style className={`layout-container`}>
            <div className="spacer m"></div>

            <div className="analysis card">

                <button className="back-button" onClick={() => history.push(`/analyze/robertson`)}>Back</button>

                <h2>Order: {order.order.tuesday_order_no}</h2> 
                <div className="spacer xs"></div>
                <pre className="order-info">
                    <p>Art nr:        {order.order.art_nr}</p>
                    <p>Wine:          {order.order.wine}</p>
                    <p>Package type:  {order.order.package_type}</p>
                    <p>Quantity:      {order.order.quantity} pcs {missingCases > 0 && (
                        <span style={{color: 'red'}}>(Missing {missingCases} pcs)</span>
                    )}</p>
                </pre>

                {/* <pre>
                    {JSON.stringify(order, null, 4)}
                </pre> */}

                <div className="spacer l"></div>

                <table>
                    <thead>
                        <tr>
                            <td>LOT</td>
                            <td>Created</td>
                            <td>Filling date</td>
                            <td>Cases</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        {order.analysis.map(a => {

                            return (
                                <tr>
                                    <td>{a.lot}</td>
                                    <td>{epochToLocalDateTime(a.created_at)}</td>
                                    <td>{a.filling_date ? new Date(a.filling_date).toLocaleDateString(undefined, {
                                        year: 'numeric', month: 'numeric', day: 'numeric',
                                    }) : '-'}</td>
                                    <td>{a.cases ?? '-'}</td>
                                    <td>
                                        <div className="status-lights">
                                            {['vinimark', 'tuesdaywine'].map(name => {
                                                const completed = a.completed_names.includes(name);

                                                if (completed) {
                                                    return (
                                                        <div className="completed"></div>
                                                    )
                                                } else {
                                                    return (
                                                        <div className="incomplete"></div>
                                                    )
                                                }
                                            })}

                                            {a.manually_completed && <div className="manually-completed">(Manually completed)</div>}

                                        </div>
                                    </td>
                                    <td>
                                        <div className="buttons">
                                            <button onClick={handleShow(a)}>Show</button>
                                            {user.role === 1 && <button onClick={handleDelete(a)} className={deleting === a.id ? 'loading' : ''}>Delete</button>}
                                            {a.status === 'pending' && user.role === 1 && <button onClick={handleSetStatus(a, 'completed')} className={loadingStatus === a.id ? 'loading' : ''}>Set completed</button>}
                                            {a.manually_completed && a.status !== 'pending' && user.role === 1 && <button onClick={handleSetStatus(a, 'pending')} className={loadingStatus === a.id ? 'loading' : ''}>Set pending</button>}
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>

                    <tfoot>
                        <tr>
                            <td colSpan={3} style={{
                                borderTop: "solid 1px #3f3f3f",
                                paddingTop: "10px"
                            }}>
                            </td>
                            <td colSpan={2} style={{
                                borderTop: "solid 1px #3f3f3f",
                                paddingTop: "10px"
                            }}>
                                {sumCases}
                            </td>
                            <td colSpan={5} style={{
                                borderTop: "solid 1px #3f3f3f",
                                paddingTop: "10px"
                            }}>
                                <div className="buttons">
                                    <button onClick={handleCreate} className={createLoading ? 'loading' : ''}>Create Analysis</button>
                                </div>
                            </td>
                        </tr>
                    </tfoot>

                </table>

                <div className="spacer l"></div>
            </div>
        </Style>
    )
}

const Style = styled.main`

    width: 1000px;

    .analysis {
        grid-column: 1 / 13;

        .back-button {
            display: inline-block;
            width: min-content;
        }

        .order-info {
            p {
                font-size: 16px;
                font-family: monospace;
                font-weight: normal;
                margin: 5rem 0;
            }
        }

        thead td {
            font-size: 18px;
            padding-bottom: 5px;
        }

        .buttons {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            gap: 10px;
        }
    }




    .status-lights {
        display: flex;
        gap: 4px;
        margin-right: 20px;
        margin-bottom: 0.5em;

        div:not(.manually-completed) {
            font-size: 16px;
            width: 1em;
            height: 1em;
            border-radius: 50%;
            background-color: var(--color-text-negative);

            &.completed {
                background-color: var(--color-text-positive);
            }
        }

        .manually-completed {
            font-size: 12px;
            color: var(--color-text-positive);
            font-weight: 400;
            margin-left: 10px;
        }
    }

    .mobile & {
        h2 {
            font-size: 20px;
        }
    }

`