import React, { useContext } from 'react';
import 'bp-date-util';
import { useEffect } from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	useHistory,
	Redirect,
	useLocation
} from "react-router-dom";
import styled from 'styled-components';
import Product from './pages/Product/Product';
import CompJS, { useIsTV } from './util/Util';
import ProductList from './pages/ProductList/ProductList';
import Loader from './components/Loader';
import LoaderContext, { LoaderProvider } from './context/LoaderContext';
import { useState } from 'react';
import Screen from './pages/Screen';
import Screens from './pages/Screens';
import ReactGA from 'react-ga';
import Login from './pages/Login';
import TuesdayWine from './util/TuesdayWine';
import Users from './pages/Users';
import jwtDecode from 'jwt-decode';
import Orders from './pages/Orders';
import SBImages from './pages/SBImages/SBImages';
import Analyze from './pages/Analyze';
import SingleAnalyze from './pages/SingleAnalyze';
import AnalyzeVinimark from './pages/AnalyzeVinimark';
import SingleAnalyzeVinimark from './pages/SingleAnalyzeVinimark';
import VinimarkOrder from './pages/VinimarkOrder';
import ProductStock from './pages/ProductStock';



const VERSION = '0.0.2';



Date.prototype.getAbsoluteWeek = function() {
	let w = this.getWeek() + '';
	if (w.length == 1) w = '0' + w;
	return Number(this.getFullYear() + w);
}

console.log('VERSION: ' + VERSION)

function App() {

	const [loader, setLoader] = useState({visible: true, message: 'loading...'})
	const [loggedIn, setLoggedIn] = useState(localStorage.getItem('user'))

	useEffect(() => {
		console.log('init')
		CompJS.init();
		ReactGA.initialize('UA-167907185-26');
	}, [])



	return (
		<LoaderProvider value={{loader, setLoader, loggedIn, setLoggedIn}}>
			<Router>
				
				<Loader visible={loader.visible}>{loader.message}</Loader>
				
				{loggedIn ? (
					<>
						<LoggedIn loggedIn={loggedIn} />
						<footer style={{ display: 'flex', justifyContent: 'center', marginTop: 20, marginBottom: 40 }}>
							<button style={{margin: 'auto'}} onClick={() => {
								setLoggedIn(null)
								localStorage.removeItem('user')
							}}>Logout</button>
						</footer>
					</>
				) : (
					<Login />
				)}

			</Router>
		</LoaderProvider>
	);
}

function LoggedIn({ loggedIn }) {


	let location = useLocation();

	const isTV = useIsTV();
	if (isTV) document.documentElement.classList.add('is-tv');

    const user = jwtDecode(loggedIn);

	useEffect(() => {
		setTimeout(() => {
			ReactGA.pageview(location.pathname)
		}, 0)
	}, [location])

	const routes = [
		{
			path: "/analyze/casas/:id",
			component: SingleAnalyze
		},
		{
			path: "/analyze/casas",
			component: Analyze
		},
		{
			path: "/analyze/robertson/:order_id/:id",
			component: SingleAnalyzeVinimark
		},
		{
			path: "/analyze/robertson/:order_id",
			component: VinimarkOrder
		},
		{
			path: "/analyze/robertson",
			component: AnalyzeVinimark
		},
		{
			path: "/screens",
			component: Screens
		},
		{
			path: "/screen/:id",
			component: Screen
		},
		{
			path: "/:productNumber/:packageNumber",
			component: Product
		},
		{
			path: "/users",
			component: Users
		},
		{
			path: "/orders",
			component: Orders
		},
		{
			path: "/sb-images",
			component: SBImages
		},
		{
			path: "/stock",
			component: ProductStock
		},
		{
			path: "/",
			component: ProductList
		}
	]

	function filter(route) {

		// Admin have access to all routes.
		if (user.role === 1) return true;

		// Tuesday Wine standard account.
		if (user.role === 2) {
			if ([
				'/',
				'/:productNumber/:packageNumber',
				'/orders',
				'/screen/:id',
				'/screens',
				'/analyze/casas',
				'/analyze/casas/:id',
				'/analyze/robertson',
				'/analyze/robertson/:order_id',
				'/analyze/robertson/:order_id/:id',
			].includes(route.path)) return true;

			return false;
		}

		// Users with analyze_access only have access to analyze routes.
		if (user.analyze_access) {
			if ([
				'/analyze/casas',
				'/analyze/casas/:id',
				'/analyze/robertson',
				'/analyze/robertson/:order_id',
				'/analyze/robertson/:order_id/:id',
			].includes(route.path)) return true;

			if (!user.standard_access) {
				return false;
			}
		}

		// Vinimark
		if (user.role === 3) {
			if ([
				'/',
				'/:productNumber/:packageNumber',
				'/analyze/robertson',
				'/analyze/robertson/:order_id',
				'/analyze/robertson/:order_id/:id',
			].includes(route.path)) return true;

			return false;
		}

		// AZ
		if (user.role === 4) {
			if ([
				'/',
				'/:productNumber/:packageNumber'
			].includes(route.path)) return true;

			return false;
		}

		// Jeanneret
		if (user.role === 5) {
			if ([
				'/',
				'/:productNumber/:packageNumber'
			].includes(route.path)) return true;

			return false;
		}

		// Casas Patronales
		if (user.role === 6) {
			if ([
				'/',
				'/:productNumber/:packageNumber',
				'/orders',
				'/analyze/casas',
				'/analyze/casas/:id'
			].includes(route.path)) return true;

			return false;
		}

		// Globus
		if (user.role === 7) {
			if ([
				'/',
				'/:productNumber/:packageNumber',
			].includes(route.path)) return true;

			return false;
		}


		return false;
	}

	return (
		<Switch>

			{routes.filter(filter).map((route, i) => {
				return (
					<Route key={i} exact path={route.path} component={route.component} />
				)
			})}

			<Route path="*" component={Fallback} />

		</Switch>
	)
}


function Fallback() {
    const {setLoader} = useContext(LoaderContext);

    useEffect(() => {
        setLoader(true)
    }, [])

	return (
		<div>
			<div className="spacer l"></div>
			<div className="spacer l"></div>
			<h2 style={{ textAlign: 'center' }}>No access</h2>
			<p style={{ textAlign: 'center' }}>Looks like you do not have access to this page.</p>
		</div>
	)
}

export default App;
