import { sha256 } from 'js-sha256';
import jwtDecode from 'jwt-decode';
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import styled from 'styled-components'
import Icon from '../components/Icon';
import Table from '../components/Table';
import LoaderContext from '../context/LoaderContext';
import TuesdayWine from '../util/TuesdayWine';

export default function Users() {
    
    const {loader, setLoader, loggedIn} = useContext(LoaderContext);
    const [users, setUsers] = useState([])
    const [roles, setRoles] = useState([])
    const history = useHistory();

    useEffect(() => {

        try {
            let user = jwtDecode(loggedIn);
            
            if (user.role != 1)
                history.push('/')
        } catch(e) {
            history.push('/')
        }

        (async () => {
            setUsers(await TuesdayWine.getUsers());
            setRoles(await TuesdayWine.getRoles());

            setLoader(false)

        })()
    }, [])

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [showCreateUser, setShowCreateUser] = useState(false)

    const closeCreateUser = () => {
        setName('')
        setEmail('')
        setPassword('')
        setShowCreateUser(false);
    }

    const createUser = async e => {
        e.preventDefault();

        await TuesdayWine.createUser({
            username: name,
            email,
            password: sha256(password),
            role: 2
        })

        closeCreateUser()

        setUsers(await TuesdayWine.getUsers());
    }



    const [showDeleteUser, setShowDeleteUser] = useState(false)




    return (
        <Style className={`card`}>

            {showCreateUser && <div id="create-user" className="popup">
                <form className="card" onSubmit={createUser} autoComplete="off">
                    <h2>Create user</h2>
                    <div className="spacer s"></div>
                    <input autoComplete="new-password" required type="text" placeholder="Name" value={name} onChange={e => setName(e.target.value)}/>
                    <input autoComplete="new-password" required type="email" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)}/>
                    <input autoComplete="new-password" required type="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)}/>

                    <div className="spacer s"></div>
                    <div className="row justify-center">
                        <input type="submit" value="Create"/>
                        <div className="spacer wxs"></div>
                        <input type="button" value="Cancel" onClick={closeCreateUser}/>
                    </div>
                </form>
            </div>}

            {showDeleteUser && <div id="delete-user" className="popup">
                <div className="card">
                    <h2>Confirm delete</h2>
                    <div className="spacer s"></div>
                    <p>The user <b>'{users.find(user => user.id == showDeleteUser)?.username}'</b> will be deleted permanently!<br/> Are you sure?</p>
                    <div className="spacer s"></div>

                    <div className="row justify-end">
                        <input type="button" value="Yes, Delete" onClick={async () => {
                            await TuesdayWine.deleteUser(showDeleteUser);
                            setUsers(await TuesdayWine.getUsers())
                            setShowDeleteUser(false);
                        }}/>
                        <div className="spacer wxs"></div>
                        <input type="button" value="No, Cancel" onClick={() => setShowDeleteUser(false)}/>
                    </div>
                </div>
            </div>}

            <h2 className="color-blue-gray">Users</h2>
            <div className="spacer m"></div>

            <p>Användare får olika behörigheter baserat på sin "Role" och är då låsta till delar av systemet.<br/>För tillgång till analystavlan så ska "Analysis" vara Yes, som standard så kommer det alternativet då göra att användaren inte kan komma åt försäljningssiffror etc. Om användaren även ska kunna komma åt detta så ska "Standard access" också vara Yes<br/><br/>OBS! För att ändringar i roller ska uppdateras så måste användaren logga ut och logga in igen.</p>

            <div className="spacer m"></div>

            <Table 
                data={users}
                limit={100}
                structure={[
                    {
                        heading: 'Name',
                        key: 'username',
                        sortable: true
                    },
                    {
                        heading: 'Email',
                        key: 'email',
                        sortable: true
                    },
                    {
                        heading: 'Role',
                        component: (row) => {
                            return (
                                <select onChange={async e => await TuesdayWine.updateUser(row.id, {role: e.target.value})}>
                                    {roles.map(role => <option selected={role.id == row.role} key={role.id} value={role.id}>{role.name}</option>)}
                                </select>
                            )
                        }
                    },
                    {
                        heading: 'Analysis?',
                        component: (row) => {
                            return (
                                <select onChange={async e => await TuesdayWine.updateUser(row.id, {analyze_access: e.target.value})}>
                                    <option value="0" selected={row.analyze_access === 0}>No</option>
                                    <option value="1" selected={row.analyze_access === 1}>Yes</option>
                                </select>
                            )
                        }
                    },
                    {
                        heading: 'Standard access?',
                        component: (row) => {
                            return (
                                <select onChange={async e => await TuesdayWine.updateUser(row.id, {standard_access: e.target.value})}>
                                    <option value="0" selected={row.standard_access === 0}>No</option>
                                    <option value="1" selected={row.standard_access === 1}>Yes</option>
                                </select>
                            )
                        }
                    },
                    {
                        heading: '',
                        component: (row) => {
                            return (
                                <div className="icons">
                                    <Icon onClick={() => setShowDeleteUser(row.id)}>delete</Icon>
                                </div>
                            )
                        }
                    }
                ]}
            />
            <div className="spacer m"></div>
            <input type="button" value="Add new user" onClick={() => setShowCreateUser(true)}/>
        </Style>
    )
}

const Style = styled.main`

    grid-column: auto;
    padding: 15rem;

    grid-column: span 10;

    .popup {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        z-index: 10000;
        display: flex;
        justify-content: center;
        align-items: center;

        .card {
            padding: 15em;
        }
    }

    .icons {
        display: flex;
        align-items: center;
    }

`