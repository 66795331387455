import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import TuesdayWine from '../../../util/TuesdayWine';

export default function AnalysisRow({ order, canDelete, onDeleted, component }) {
    const [loadingCreate, setLoadingCreate] = useState(false)
    const [loadingDelete, setLoadingDelete] = useState(false)

    const history = useHistory();

    const firstAnalysis = order.analysis[0];
    const ongoing = firstAnalysis != null;

    function handleShow() {
        history.push(`/analyze/robertson/${order.order_id}`)
    }

    async function handleCreate() {

        setLoadingCreate(true)

        const lotNumber = prompt('Enter lot number');

        if (!lotNumber || lotNumber.trim() == '') {
            setLoadingCreate(false)
            return alert('Invalid lot number');
        }

        const fillingDate = prompt('Enter filling date (YYYY-MM-DD)');
        if (!fillingDate || fillingDate.trim() == '') {
            setLoadingCreate(false)
            return alert('Invalid filling date');
        }

        if (!fillingDate.match(/^\d{4}-\d{2}-\d{2}$/)) {
            setLoadingCreate(false)
            return alert('Invalid date format');
        }

        const cases = prompt('Enter number of cases, defaults to 0');

        let res = await TuesdayWine.post(`/analyze`, {
            order_id: order.order_id,
            lot: lotNumber,
            filling_date: fillingDate,
            producer: 'VM',
            cases: Number(cases ?? 0)
        })
        setLoadingCreate(false)

        if (res === undefined || res.analysis === undefined) {
            return alert('Failed to create analysis');
        }

        history.push(`/analyze/robertson/${order.order_id}`)
    }

    async function handleDelete() {

        const confirm = window.confirm('Are you sure you want to delete this analysis?')
        if (!confirm) return;

        setLoadingDelete(true)

        let res = await TuesdayWine.delete(`/analyze/${firstAnalysis.id}`)
        setLoadingDelete(false)

        console.log(res)

        if (res === undefined || res.success !== true) {
            return alert('Failed to delete analysis');
        }

        if (onDeleted) onDeleted(firstAnalysis.id)
    }

    async function handleHide() {

        const confirm = window.confirm('Are you sure you want to hide this order?')
        if (!confirm) return;

        setLoadingDelete(true)
    
        let res = await TuesdayWine.put(`/analyze/${order.order_id}/hide`)

        setLoadingDelete(false)

        if (res === undefined || res.success !== true) {
            return alert('Failed to hide order');
        }

        if (onDeleted) onDeleted(order.order_id)
    }

    if (component) {
        return component({
            order,
            canDelete,
            onDeleted,
            handleShow,
            handleCreate,
            handleDelete,
            loadingCreate,
            loadingDelete,
            ongoing
        })
    }

    return (
        <tr>
            <td>
                <div className="order-no">{order.order.robertson_order_no ?? '-'}</div>
                <div className="lot-numbers">
                    {order.analysis.map((analysis, i) => {
                        return <Link 
                        to={`/analyze/robertson/${order.order_id}/${analysis.id}`}
                        style={{
                            color: analysis.status === 'completed' ? 'green' : analysis.completed_names.length > 0 ? 'yellow' : 'red',
                            display: 'inline-block',
                            marginRight: '7px',
                            fontSize: '10px',
                            fontWeight: '400',
                            fontStyle: 'italic'
                        }} key={i}>{analysis.lot}</Link>
                    })}
                </div>
            </td>
            <td>
                <a href={`https://kylix.skanlog.com/kylix/purchase_pkg.purchase_order_detail?p_order_no=${order.order.tw_3pl_order_no}`} target="_blank" rel="noreferrer" style={{color:'white'}}>
                    {order.order.tuesday_order_no}
                </a>
            </td>
            <td>{new Date(order.order.eta_stockholm).toLocaleDateString('sv-SE', {
                year: 'numeric', month: 'numeric', day: 'numeric',
            })}</td>
            <td>{ongoing ? new Date(firstAnalysis.created_at).toLocaleDateString('sv-SE', {
                year: 'numeric', month: 'numeric', day: 'numeric',
            }) : '-'}</td>
            <td>{order.order.wine ?? '-'}</td>
            <td>
                {ongoing && (
                    <div className="status-lights">
                        {order.analysis.map((analysis, i) => {
                            let status = 'incomplete';

                            if (analysis.completed_names.length > 0) {
                                status = 'partial';
                            }

                            if (analysis.status === 'completed') {
                                status = 'completed';
                            }

                            return (
                                <div key={i} className={`${status}`}></div>
                            )
                        })}
                    </div>
                )}
            </td>
            <td>
                <div className="action-button">
                    {ongoing && <button onClick={handleShow}>Show</button>}
                    {!ongoing && <button className={`${loadingCreate ? 'loading' : ''}`} onClick={handleCreate}>Create</button>}
                </div>
            </td>
            <td>
                {canDelete && ongoing && <button className={`${loadingDelete ? 'loading' : ''}`} onClick={handleDelete}>Delete</button>}
                {canDelete && !ongoing && <button className={`${loadingDelete ? 'loading' : ''}`} onClick={handleHide}>Hide</button>}
            </td>
        </tr>
    )
}
